import React from 'react';
import { Link, useHistory } from 'react-router-dom';

class ShareWidget extends React.Component{
  constructor(props){
    super(props);
    this.shareText = this.shareText.bind(this);
    this.sanitize = this.sanitize.bind(this);
  }
  sanitize(s){
    if(s){
      s = s.replace(/&/g, '%26');
      s = s.replace(/#/g, '%23');
      s = s.replace(/\+/g, '%2B');
      s = s.replace(/@/g, '%40');
      s = s.replace(/:/g, '%3A');
      return s;
    }
  }
  shareText(channel, object){
    let title = object.name;
    let caption = object.description;
    let img = object.poster;
    let url = window.location.href;
    let path = window.location.pathname;
    let hostname = 'd2vuum2n1z62fc.cloudfront.net';
    let link = '';
    switch(channel){

      case 'facebook':
        link = `https://www.facebook.com/dialog/share?app_id=407352236856619&display=popup&href=${url}`;
        return link
      case 'twitter':
        link = `https://twitter.com/intent/tweet?text=${caption+' '+img}&url=${url}&hashtags=videomasters&via=brightcove`
        return link
      case 'linkedin':
        link = `https://www.linkedin.com/shareArticle/?url=${this.sanitize(encodeURI(url))}&mini=true`
        return link
      default:
        return ''
    }
  }
  render(){
    return(
      <div className="shareWrap">
        <span>Share This:</span>
        <a href={this.shareText('facebook', this.props.video)} rel="noopener noreferrer" target="_blank">
          <svg xmlns='http://www.w3.org/2000/svg' width='34.492' height='34.492' viewBox='0 0 34.492 34.492'><defs></defs><path fill="#fff" d='M991.167,129.947a17.246,17.246,0,1,0,17.246,17.246A17.266,17.266,0,0,0,991.167,129.947Zm4.784,10.23a.26.26,0,0,1-.26.26h-2.075a.922.922,0,0,0-.921.922v2.171h2.95a.261.261,0,0,1,.26.282l-.259,3.061a.26.26,0,0,1-.259.238h-2.691V157.27a.26.26,0,0,1-.26.26h-3.712a.26.26,0,0,1-.26-.26V147.112h-1.856a.26.26,0,0,1-.26-.26v-3.061a.26.26,0,0,1,.26-.26h1.856v-2.95a3.723,3.723,0,0,1,3.724-3.724h3.5a.26.26,0,0,1,.26.26Z' transform='translate(-973.92 -129.947)'/></svg>
        </a>
        <a href={this.shareText('twitter', this.props.video)} rel="noopener noreferrer" target="_blank">
          <svg xmlns='http://www.w3.org/2000/svg' width='34.492' height='34.492' viewBox='0 0 34.492 34.492'><path fill="#FFF" d='M905.63,171.947a17.246,17.246,0,1,0,17.246,17.246A17.266,17.266,0,0,0,905.63,171.947Zm9.987,10.97a.486.486,0,0,1-.1.32,7.978,7.978,0,0,1-1.6,1.673c-.092.068-.245.14-.245.27,0,3.322-.849,6.64-3.27,9.046a12.171,12.171,0,0,1-11.815,2.763,11.3,11.3,0,0,1-2.285-.914c-.241-.131-.479-.268-.711-.422-.06-.047-.1-.092-.053-.123s.089-.027.272,0a8.063,8.063,0,0,0,3.419-.437,5.223,5.223,0,0,0,2.25-1.193c.009-.013.018-.013.026-.027-.15-.035-.3-.035-.449-.061a4.412,4.412,0,0,1-3.3-2.76c-.044-.11,0-.167.115-.145a4.732,4.732,0,0,0,1.568-.049,2.464,2.464,0,0,1-.531-.162,4.25,4.25,0,0,1-2.619-3.388,2.567,2.567,0,0,1-.026-.475c.009-.109.057-.145.154-.083a4.21,4.21,0,0,0,1.6.448c-.18-.14-.347-.294-.509-.448a4.2,4.2,0,0,1-.717-4.965c.08-.119.132-.119.229-.009a12.879,12.879,0,0,0,8.288,4.219c.088.013.1,0,.083-.1a4.448,4.448,0,0,1,.043-1.732,4.226,4.226,0,0,1,.819-1.64,4.39,4.39,0,0,1,1.372-1.12,4.282,4.282,0,0,1,1.7-.48,4.069,4.069,0,0,1,1.792.289,4.27,4.27,0,0,1,1.169.716,1.942,1.942,0,0,1,.211.215.16.16,0,0,0,.166.044,10.359,10.359,0,0,0,2.412-.923.114.114,0,0,1,.107-.009c.035.027.022.071,0,.106a3.734,3.734,0,0,1-.676,1.232c-.193.24-.675.832-1,.887a8.249,8.249,0,0,0,2.03-.58c.082-.037.075-.053.075,0Z' transform='translate(-888.384 -171.947)'/><path d='M909.029,212.228c-.332,0-.026,0,0,0Z' transform='translate(-895.927 -186.775)'/><path fill="#FFF" d='M910.188,212.247c.356,0,0-.034,0,0Z' transform='translate(-896.41 -186.776)'/></svg>
        </a>
        <a href={this.shareText('linkedin', this.props.video)} rel="noopener noreferrer" target="_blank">
          <svg xmlns='http://www.w3.org/2000/svg' width='34.434' height='34.433' viewBox='0 0 34.434 34.433'><path fill="#FFF" d='M917.954,126.174v-.038c-.008.013-.019.026-.026.038Z' transform='translate(-898.995 -110.944)'/><path fill="#FFF" d='M905.183,102.094A17.217,17.217,0,1,0,922.4,119.311,17.237,17.237,0,0,0,905.183,102.094Zm-4.306,24.956a.287.287,0,0,1-.288.288h-3.318a.287.287,0,0,1-.288-.288V115.914a.288.288,0,0,1,.288-.287h3.318a.288.288,0,0,1,.288.287Zm-2.063-12.913a2.084,2.084,0,1,1,2.085-2.085A2.085,2.085,0,0,1,898.814,114.137Zm16.1,12.913a.287.287,0,0,1-.287.288H911.31a.287.287,0,0,1-.287-.288v-5.978c0-1.573-.562-2.648-1.972-2.648a2.131,2.131,0,0,0-2,1.424,2.668,2.668,0,0,0-.128.949v6.253a.288.288,0,0,1-.288.288h-3.318a.287.287,0,0,1-.288-.288c.008-1.592.041-9.317.007-11.133a.286.286,0,0,1,.288-.291h3.311a.288.288,0,0,1,.288.287v1.372a3.866,3.866,0,0,1,3.509-1.935c2.562,0,4.482,1.674,4.482,5.271Z' transform='translate(-887.966 -102.094)'/></svg>
        </a>
      </div>
    )
  }
}
export default ShareWidget;
