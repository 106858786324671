const assetLookup = {
  'video-masters-episode-1':{
    'zip':'https://files.brightcove.com/video-masters/vm-bonus-material-chapter1.pdf',
    'items':[
      {
        'label':'Chapter 1 Bonus Materials',
        'imgUrl':'/vm-bonus-material-chapter1.jpg',
        'desc':'Branding Your Videos'
      }
    ],
    'related':[
      {
        'title':'YouTube - A risky choice for brands',
        'link':'https://www.brightcove.com/en/blog posts/2019/02/youtube-risky-choice-brands',
        'image':'https://www.brightcove.com/images/2627a6d5-4dfd-4ac7-959d-c4f118f21aa1?presets=post-banner&dpr=1',
        'type':'blog posts'
      },
      {
        'title':'Planning, shooting and editing cross-platform marketing videos: strategies for your next brand campaign',
        'link':'https://www.brightcove.com/en/blog posts/2019/07/planning-shooting-and-editing-cross-platform-marketing-videos-strategies-your-next-brand-campaign',
        'image':'https://www.brightcove.com/images/79b0ec71-00c6-4039-afc6-6b9497231f1c?presets=post-banner&dpr=1',
        'type':'blog posts'
      },
      {
        'title':'3 Reasons why brands need to live stream on social media',
        'link':'https://www.brightcove.com/en/blog posts/2019/05/3-reasons-why-brands-need-live-stream-social-media',
        'image':'https://www.brightcove.com/images/2a9a2eff-3a50-4d63-b45e-8a89117bf645?presets=post-banner&dpr=1',
        'type':'blog posts'
      },
      {
        'title':'Video brings video storytelling to SPH\'s advertising campaigns',
        'link':'https://www.brightcove.com/en/customers/sph',
        'image':'https://httpsak-a.akamaihd.net/1160438696001/1160438696001_5534468721001_5508167318001-vs.jpg?pubId=1160438696001&videoId=5508167318001',
        'type':'case studies'
      }
    ]
  },
  'video-masters-episode-2':{
    'zip':'https://files.brightcove.com/video-masters/vm-bonus-material-chapter2.pdf',
    'items':[
      {
        'label':'Chapter 2 Bonus Materials',
        'imgUrl':'/vm-bonus-material-chapter2.jpg',
        'desc':'Video in the Customer Journey'
      }
    ],
    'related':[
      {
        'title':'Amp up your Account Based Marketing Strategy with Video',
        'link':'https://www.brightcove.com/en/webinars?videoId=6029891937001',
        'image':'https://cf-images.us-east-1.prod.boltdns.net/v1/jit/1160438696001/3eb1dcf7-25c1-4cc6-bb8e-43888d2d3cbf/main/1280x720/14m55s168ms/match/image.jpg',
        'type':'webinars'
      },
      {
        'title':'Better Video in 5 Steps',
        'link':'https://www.brightcove.com/en/webinars?videoId=6010725257001',
        'image':'https://cf-images.us-east-1.prod.boltdns.net/v1/jit/1160438696001/42491d2b-4b2f-4bec-bf93-6370551aa847/main/1280x720/23m5s632ms/match/image.jpg',
        'type':'webinars'
      },
      {
        'title':'How to incorporate video into the customer journey',
        'link':'https://www.brightcove.com/en/blog posts/2019/01/how-incorporate-video-customer-journey',
        'image':'https://www.brightcove.com/images/968a5d44-18d6-41de-916a-1ffc70237439?presets=post-banner&dpr=1',
        'type':'blog posts'
      },
      {
        'title':'Incorporating customer stories into your marketing strategy',
        'link':'https://www.brightcove.com/en/blog posts/2019/03/incorporating-customer-stories-your-marketing-strategy',
        'image':'https://www.brightcove.com/images/61969e38-7a57-4d90-b14a-7eed9d8d5949?presets=post-banner&dpr=1',
        'type':'blog posts'
      }
    ]
  },
  'video-masters-episode-3':{
    'zip':'https://files.brightcove.com/video-masters/vm-bonus-material-chapter3.pdf',
    'items':[
      {
        'label':'Chapter 3 Bonus Materials',
        'imgUrl':'/vm-bonus-material-chapter3.jpg',
        'desc':'Maximizing your Audience'
      }
    ],
    'related':[
      {
          'title':'Fresh insights for your social video strategy',
          'link':'https://www.brightcove.com/en/webinars?videoId=6034329615001',
          'image':'https://cf-images.us-east-1.prod.boltdns.net/v1/static/1160438696001/02b662ea-71d7-4cb5-a84f-c28370d3c638/3086b2e7-6b9e-4fe3-b472-0e15ff8c9b5d/1280x720/match/image.jpg',
          'type':'webinars'
      },
      {
        'title':'Workerbee.TV Realizes 30% Annual Growth Delivering Turn-Key Video Solutions for Professional Business Associations',
        'url':'https://www.brightcove.com/en/customers/workerbeetv',
        'image':'https://www.brightcove.com/images/dba0abda-d7f0-424f-a351-29febd9fa08b?presets=case-study&dpr=1',
        'type':'case studies'
      },
      {
        'title':'Avanade\'s Video Performance Skyrockets Using Brightcove Audience',
        'link':'https://www.brightcove.com/en/customers/avanade',
        'image':'https://cf-images.us-east-1.prod.boltdns.net/v1/static/1160438696001/52f04f37-c5a5-41f4-a49e-b5158b251979/a4725fdf-2da9-4184-b5d8-abae467986ad/1280x720/match/image.jpg',
        'type':'case studies'
      },
      {
        'title':'High Cost of Free',
         'link':'https://www.brightcove.com/en/22-minute-webinars/22minutes-mkt/6091327150001',
         'image':'https://cf-images.us-east-1.prod.boltdns.net/v1/static/1160438696001/996dfb31-5dc8-4244-ab40-4aeadd2dbacc/87629b32-1bc3-4639-ba69-6a46b67b780a/1280x720/match/image.jpg',
         'type':'webinars'
      }
    ]
  },
  'video-masters-episode-4':{
    'zip':'https://files.brightcove.com/video-masters/vm-bonus-material-chapter4.pdf',
    'items':[
      {
        'label':'Chapter 4 Bonus Materials',
        'imgUrl':'/vm-bonus-material-chapter4.jpg',
        'desc':'Producing Your Videos'
      }
    ],
    'related':[
      {
        'title':'Video Is Making an Impression at Dunkin\' Brands: In-House Production Team Makes Video an Essential Communications Tool',
        'link':'https://www.brightcove.com/en/customers/dunkin-brands',
        'image':'https://cf-images.us-east-1.prod.boltdns.net/v1/static/1160438696001/b8baf0cb-52e9-40df-aaba-a091b62f3d80/b34c31f4-443d-4be6-932a-b479ec32d030/960x540/match/image.jpg',
        'type':'case studies'
      },
      {
        'title':'Fact or Fiction: ‘Short and Sweet’ is the Best Video Strategy',
        'link':'https://www.brightcove.com/en/blog posts/2018/07/fact-or-fiction-short-and-sweet-best-video-strategy',
        'image':'https://brightcove04pmdo-a.akamaihd.net/4828955560001/4828955560001_5808771886001_5808335201001-vs.jpg?pubId=1160438696001&videoId=5811397854001',
        'type':'blog posts'
      },
      {
        'title':'A look at Brightcove\'s in-house video studio equipment',
        'link':'https://www.brightcove.com/en/blog posts/2019/06/look-brightcoves-house-video-studio-equipment',
        'image':'https://www.brightcove.com/images/eb8dc37e-ec34-4b7e-925c-cdf804212ecf?presets=post-banner&dpr=1',
        'type':'blog posts'
      },
      {
        'title':'Forget the Emmy — Take the Pressure Off Your Video Production Process',
        'link':'https://www.brightcove.com/en/blog posts/2018/06/forget-emmy-take-pressure-your-video-production-process',
        'image':'https://www.brightcove.com/images/13de1d72-f5bc-4a77-abe0-c68aadf3516c?presets=post-banner&dpr=1',
        'type':'blog posts'
      }
    ]
  },
  'video-masters-episode-5':{
    'zip':'https://files.brightcove.com/video-masters/vm-bonus-material-chapter5.pdf',
    'items':[
      {
        'label':'Chapter 5 Bonus Materials',
        'imgUrl':'/vm-bonus-material-chapter5.jpg',
        'desc':'Analyzing Your Videos'
      }
    ],
    'related':[
      {
        'title':'Video + Marketing Automation = Unstoppable Duo',
        'link':'https://www.brightcove.com/en/webinars?videoId=6043346833001',
        'image':'https://cf-images.us-east-1.prod.boltdns.net/v1/static/1160438696001/8f170f09-86f8-4fb4-847a-bb765cff72b4/b2de831a-1652-491e-82b1-e823d55bdcc3/1280x720/match/image.jpg',
        'type':'webinars'
      }
    ]
  }
}
exports.assetLookup = assetLookup;
exports.allAssets = ()=>{
  let allAssets = {};
  Object.keys(this.assetLookup).forEach((item)=>{
    this.assetLookup[item]['related'].forEach((related)=>{
      if(allAssets[related['type']]){
        allAssets[related['type']].push(related);
      }else{
        allAssets[related['type']] = [];
        allAssets[related['type']].push(related);
      }
    })
  });
  return allAssets;
}
