// check if user downloaded already
// check referral resource
// check active video


import React from 'react';
import Cookies from 'js-cookie';
import Countries from './countrySelect';
import { assetLookup as lookup }  from './assetLookup';

const blindForm = ({email, label, url}) => `http://s906.t.eloqua.com/e/f2.aspx?elqFormName=blindform_content&elqSiteID=906&emailAddress=${email}&response_offer_id=7011O0000038R4Q&assetURL=${url}&offer_asset_name=${url}`;

const assetLookup = lookup;
class Form extends React.Component{
  state = {
    activeAssets:null,
    activeVideo:null,
    activeUrl:"",
    cid:"",
    pid:"",
    email:"",
    submitted:false,
    loading:false,
    assetURL:"https://files.brightcove.com/video-masters/vm-ebook.pdf",
    checked:false
  }
  constructor(props){
    super(props);
    this.getAssets = this.getAssets.bind(this);
    this.manageUrlParams = this.manageUrlParams.bind(this);
    this.manageCookies = this.manageCookies.bind(this);
    this.handleCountryVal = this.handleCountryVal.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
  }
  sendForm(e){
    if(e){
      console.log(e)
      e.preventDefault();
    }
    // check validation

    const endpoint = "https://secure.eloqua.com/e/f2";
    let parent = this;
    let form = document.getElementById('videomasters-form');
    let formfields = new FormData(form);
    if(document.querySelector('.checked') == null && formfields.has('receive_marketing_communication')){
      formfields.delete('receive_marketing_communication');
    }
    if(document.querySelector('.checked') !== null && (formfields.has('receive_marketing_communication') == false) ){
      formfields.append('receive_marketing_communication', 'on');
    }
    if(form.checkValidity()){
      this.setState({loading:true});
      // console.log('form fields', formfields);
      fetch(endpoint, {
        method: 'POST',
        body: formfields
      }).then( function(response){
        // console.log(response);
        if(response.status && response.status == 200){
          // show thank you message
          form.reset();
          let url = assetLookup[parent.props.video.reference_id].zip;
          console.log("show url", assetLookup[parent.props.video.reference_id].zip);
          parent.setState({submitted:true, loading:false, activeUrl:url})
        }
      }).catch( function(error){ console.error('Error:', error) });
    }else{
      parent.setState({invalid:true});
    }
    return
  }
  handleDownload(url){
    this.setState({activeUrl:url});
  }
  getAssets(id){
    let newAssets = assetLookup[id];
    this.setState({activeAssets:newAssets});
  }
  componentDidMount(){
    this.manageUrlParams();
  }
  manageUrlParams(){
    // check if email exists
    let writtenCookies = Cookies.getJSON('BC_MKTG_AUTO');
    if(writtenCookies && writtenCookies['email']){
      // prepare url for DL
      // let dlLink = blindForm({email:writtenCookies['email'], link:this.state.activeAssets.zip, label:this.props.video.name})
      this.setState({submitted:true, email:writtenCookies['email']});
    }
    let params = new URLSearchParams(window.location.search);
    let keys = ['cid', 'pid'];
    let mktgObj = {};
    for(const [key, value] of params){

      if(keys.indexOf(key) > -1){
        mktgObj[key] = value;
      }
      if(Object.keys(mktgObj).length == 2){
        if(params.get('email')){
          mktgObj['email'] = params.get('email');
        }
        this.manageCookies('BC_MKTG_AUTO', mktgObj);
        this.setState(mktgObj);
      }
    }

  }
  handleCountryVal(e){
    if(e.target.value == 'United States'){
      this.setState({checked:true});
    }else{
      this.setState({checked:false});
    }
  }
  toggleChecked(e){
    console.log('currently', this.state.checked);
    if(this.state.checked == false){
      this.setState({checked: true}, ()=>{
        // console.log('updated checked to true', this.state.checked);
      });
    }else{
      this.setState({checked: false}, ()=>{
        // console.log('newChecked is false', this.state.checked);
      });
    }

    // if(e == false){
    //   this.setState({checked:true});
    // }else{
    //   this.setState({checked:false});
    // }

    // console.log('now:', this.state.checked);
  }
  manageCookies(key, val){
    Cookies.set(key, val);
  }
  trackDl(string, link){
    try{
      window.gtag('event',  'Video Masters Asset Download', {event_category:'PDF Download',   event_label:string} )
    }catch(e){
      console.log('GA ERROR');
    }
  }
  render(){
    return(
      <div className={this.state.submitted ? 'formWrap submitted':'formWrap'} >
        { this.props.video.reference_id && assetLookup[this.props.video.reference_id] && assetLookup[this.props.video.reference_id].items.map((item, index)=> <div className="item" key={index}><img src={item.imgUrl} alt={item.label} /><p><strong>{item.label}</strong> {item.desc}</p></div> )}
        <a href={ assetLookup[this.props.video.reference_id].zip} target="_blank" onClick={()=>this.trackDl(this.props.video.name,  assetLookup[this.props.video.reference_id].zip)}><button className="cta submit">Download Bonus Materials</button></a>

        <form name="content-registration-en" id="videomasters-form" className={ this.state.submitted ? 'hidden':''}>
            <h2>Download the Video Marketing Masterclass Whitepaper</h2>
            <input name="profile_solution_line" value="Digital Marketing" type="hidden" />
            <input name="profile_solution_use_case" value="" type="hidden"/>
            <input name="response_landing_page_id" id="response_landing_page_id" value="Video Masters Download" type="hidden"/>
            <input name="offer_asset_language" value="EN" type="hidden"/>
            <input name="elqFormName" value="content-registration-en" id="elqFormName" type="hidden"/>
            <input name="offer_asset_name" value="Video Masters Download" type="hidden"/>
            <input name="assetURL" id="assetURL" value={this.state.activeUrl} type="hidden"/>
            <input name="elqSiteID" value="906" type="hidden"/>
            <input name="offer_asset_url" value="https://info.brightcove.com/thanks" type="hidden"/>
            <input name="bc_mktg_uuid" id="bc_mktg_uuid" type="hidden"/>
            <input name="response_channel_id" value={this.state.cid} type="hidden"/>
            <input name="response_program_id" value={this.state.pid} type="hidden"/>
            <input name="response_asset_id" value="" type="hidden"/>
            <input name="response_keyword" value="" type="hidden"/>
            <input name="response_offer_id" id="response_offer_id" value="7011O0000038T4D" type="hidden"/>
            <div className="input-holder">
              <label htmlFor="Last Name"></label>
              <input type="text" name="first_name" id="FirstName" required="required" placeholder="First Name" className="field" required="required"/>
            </div>

            <div className="input-holder">
              <label htmlFor="First Name"></label>
              <input type="text" name="last_name" id="LastName" required="required" placeholder="Last Name" className="field" required="required"/>
            </div>
            <div className="input-holder">
              <label htmlFor="email"></label>
              <input type="email" name="email" id="email" required="required" placeholder="Email" className="field" required="required"/>
            </div>
            <div className="input-holder">
              <label htmlFor="Company"></label>
              <input type="text" name="company" id="company" placeholder="Company" className="field" required="required"/>
            </div>
		        <div className="input-holder">
              <label htmlFor="Country"></label>
              <Countries val={this.handleCountryVal} />
            </div>
            <div className="input-holder">
              <label htmlFor="Job Title"></label>
              <input type="text" name="title" id="job_title" placeholder="Title" className="field"/>
            </div>
            <div className="input-holder">
              <input type="checkbox" name="receive_marketing_communication" onChange={()=>this.toggleChecked() } id="receive_marketing_communication" defaultChecked={this.state.checked} className={this.state.checked ? 'checked':'field'} />
              <label htmlFor="receive_marketing_communication"> <span>{this.state.checked}</span> I would like to receive updates, marketing communications, and product information from Brightcove</label>

            </div>
            <span className={ this.state.invalid ? "error invalid" :"error"} opacity=".5"><small><em>Please correct the highlighted fields.</em></small></span>
          </form>
          <button className={ (this.state.submitted || this.state.loading) ? 'cta submit hidden':'cta submit'} onClick={()=>this.sendForm()}>
            Download & Subscribe
          </button>
          { (this.state.submitted || this.state.loading) && this.props.video.reference_id && <a href={this.state.assetURL} target="_blank" onClick={()=>this.trackDl(this.props.video.name, this.state.assetURL)}><button className="download">{this.state.loading ? 'Preparing Download':'Download Now'}</button></a>}
        </div>
    )
  }

}

export default Form;
