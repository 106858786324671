
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

class VideoList extends React.Component{
  constructor(props){
    super(props);
    this.duration = this.duration.bind(this);
  }
  duration(ms){
    if(typeof ms !== 'undefined'){
      ms = parseInt(ms)*1000;
      let seconds = Math.floor((ms/1000)%60);
      if(seconds < 10){
        seconds = '0'+seconds;
      }
      let minutes = Math.floor((ms / (60 * 1000)) % 60);
      if(minutes < 10){
        minutes = '0'+minutes;
      }
      let hours = Math.floor((ms / (60 * 60 * 1000)) % 60)
      if(hours > 0){
        return hours+':'+minutes+':'+seconds;
      }else{
        return minutes+':'+seconds;
      }

    }

  }
  render(){
    return(
        <section className="gridHolder">
           <div className="thumbsWrapper">
             <ul className="thumbsHolder">
              {this.props.videos.map((vid, index)=>
                <Link to={'/'+vid.reference_id} key={index}>
                  <li className='vidThumb'>
                    <div className="infoHolder">
                      <strong>{vid.name}</strong>
                      <p>{vid['description']}</p>
                      <small>{this.duration(vid.duration)}</small>
                    </div>
                    <div className="imgHolder" style={{backgroundImage:'url('+vid.poster+')', backgroundSize:'contain',  backgroundRepeat:'no-repeat', backgroundPosition:'50% 50%'}}></div>
                  </li>
                </Link>
              )}
             </ul>
           </div>
      </section>
    )
  }
}
export default VideoList;
