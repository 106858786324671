'use strict';
import React from 'react';
import { assetLookup } from './assetLookup';

class RelatedContent extends React.Component{
  constructor(props){
    super(props);
    this.contentLookup = this.contentLookup.bind(this);
  }
  contentLookup(video){
    if(assetLookup[video['reference_id']]){
      return assetLookup[video['reference_id']]['related']
    }

  }
  render(){
    return(
         <div className="relatedWrapper">
           <em>Related Content</em>
           <ul className="thumbsHolder">
            {this.contentLookup(this.props.video) && this.contentLookup(this.props.video).map((item, index)=>
              <a href={item.link} rel="noopener noreferrer" target="_blank" key={index}>
                <li className='relatedThumb'>
                  <div className="imgHolder" style={{backgroundImage:'url('+item.image+')', backgroundSize:'cover', backgroundPosition:'50% 50%'}}></div>
                  <span>{item.title}</span>
                </li>
              </a>
            )}
           </ul>
         </div>
    )
  }
}
export default RelatedContent;
