'use strict';
import React from 'react';
import ReactDOM from 'react-dom';
import Form from './form';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import VidList from './vidList';
import Sharing from './sharewidget';
import RelatedContent from './relatedContent';
import { allAssets } from './assetLookup';

// watch:
// npx babel --watch src --out-dir . --presets react-app/prod


class VideoGrid extends React.Component{
  state = {
    playlists:[{name:'IBC VOD', slug:'ibc-vod-2019', id:'6090220851001'}],
    videos:[],
    unfiltered:[],
    filteredVids:[],
    vjs_player:null,
    activePlaylist:null,
    activeVideoName:'',
    activeFilters:[],
    sortVal:null,
    activeVideoDescription:'',
    activeLink:{},
    activeVid:{},
    desc:'description',
    config:{
      'data-account':"1160438696001",
      'data-player':'D8QMZAODC',
      'controls':true
    },
    search:false,
    navActive:false
  }
  constructor(props){
    super(props);
    this.getPlayList = this.getPlayList.bind(this);
    this.activateVid = this.activateVid.bind(this);
    this.updateParams = this.updateParams.bind(this);
    this.filterVideos = this.filterVideos.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.filterCount = this.filterCount.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.duration = this.duration.bind(this);
    this.tagList = this.tagList.bind(this);
    this.readMore = this.readMore.bind(this);
    // this.trackDl = this.trackDl.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.dateString = this.dateString.bind(this);
    this.toggleNav = this.toggleNav.bind(this);

  }
  componentDidUpdate(prevProps){
    // console.log('componentDidUpdate', prevProps, this.props, this.props.match.params, prevProps.match.params, (this.props.match.params !== prevProps.match.params), Object.keys(this.state.activeVid).length);
    if(this.props.match.params !== prevProps.match.params){
      //  need to call async when params change
      this.activateVid(this.props.match.params.id, true)
    }
    if(this.props.match.params.id && Object.keys(this.state.activeVid).length == 0){
      // check active vid object; if it's empty but we have an incoming ID let's update
      this.activateVid(this.props.match.params.id, false)
    }

  }
  componentDidMount(){
    let myref = this;
    let myPlayer = window.bc(this.videoNode, this.state.config, function onPlayerReady() {
      myref.setState({vjs_player:this})
      // check search params first
      // 5802089599001
      let params = new URLSearchParams(window.location.search);
      let pl = params.get('playlist');
      let vid = params.get('video');
      if(myref.props && myref.props.match.params && myref.props.match.params.id){
        vid = myref.props.match.params.id;
        myref.getPlayList(myref.state.playlists[0].id, this, vid);
      }else{
        myref.getPlayList(myref.state.playlists[0].id, this);
      }

    });
  }
  getPlayList(id, thisPlayer, activeVid){
    let player = this.state.vjs_player;
    player.catalog.getPlaylist(id, (e, playlist)=>{
      if(e){
        console.log('error', e);
      }else{
        let activeVideo = activeVid;
        if(activeVid){
          this.activateVid(activeVideo);
        }

        // this.activateVid(activeVideo);
        this.setState({videos:playlist, unfiltered:playlist, activePlaylist:id});
      }
    });
  }
  activateVid(id, clicked, index){
    let player = this.state.vjs_player;
    let root = this;
    let refId = 'ref:'+id;
    player.catalog.getVideo(refId, (e, vid)=>{
      player.catalog.load(vid);
      root.setState({activeVideoName:vid.name, activeVideoDescription:vid.description, activeVid:vid});
      document.title = vid.name;
      if(clicked){
        // if mobile, return to top

        let scrollElem = document.querySelector('#videos');
        let scrollpos = scrollElem.scrollTop;
        if(scrollpos > 500){
          scrollElem.scrollTo(0,0);
        }
        // root.updateParams({'video':id});
        player.play();
      }
    });
    player.on('ended', ()=>{
      // load next video in playlist
      index = index || 0;
      let nextVid = this.state.videos[index+1];
      if(nextVid){
        root.activateVid(nextVid.id, true, index+1);
      }

    });
  }
  updateParams(obj){
    let params = new URLSearchParams(window.location.search);
    for(let i = 0; i < Object.keys(obj).length; i++){
      let keyString = Object.keys(obj)[i];
      params.set(keyString, obj[keyString] );
    }
    // if(Object.keys(obj).length == 0){
    //   // clear the params relevant to this app
    //   params.delete('video')
    //   params.delete('playlist')
    // }
    let qstring = params.toString();
    let updatedUrl = window.location.pathname + '?'+qstring;
    window.history.pushState({}, this.state.activeVideoName, updatedUrl);
  }
  componentWillUnmount(){
    if(this.state.vjs_player){
      this.state.vjs_player.dispose();
    }
  }
  filterVideos(){
    // check if our tags match
    let videos = this.state.unfiltered;
    let unfiltered = this.state.unfiltered;

    if(this.state.activeFilters.length > 0){
      // build an array of videos filtered by tag value
      let filtered = videos.filter((vid)=>{
        let valid = false;
        for(let i = 0; i<this.state.activeFilters.length; i++){
          let vidTags = vid['tags'];
          if(vidTags.indexOf(this.state.activeFilters[i]) > -1){
            // we found a tag, we don't have to keep checking
            valid = true;
            break;
          }
        }
        return valid;
      });
      this.setState({videos:filtered, filteredVids:filtered});
    }else{
      // reset video list, clear filtered list
      this.setState({videos:unfiltered, filteredVids:[]});
    }
  }
  searchVids(string){
      // break it out into substrings
      // let string = this.state.search;
      let qString = string.toLowerCase().split(' ');
      console.log('search string ', qString);
      // search within filtered videos if there are actively filtered vids
      // otherwise, search all videos
      let videos = this.state.filteredVids.length > 0 ? this.state.filteredVids:this.state.unfiltered;
      if(qString.length > 0 && qString[0].length > 0){
        let filtered = videos.filter((vid)=>{
          let title = vid['name'].toLowerCase();
          let desc = vid['description'] + ' ' +vid['long_description'];
          let valid = false;
          for(let i = 0; i<qString.length; i++){
            if((title.indexOf(qString[i]) > -1 ) || (desc.toLowerCase().indexOf(qString[i]) > -1) ){
              valid = true;
              break;
            }
          }
          return valid;
        });
        console.log(filtered, this.state.activeFilters);
        this.setState({videos:filtered, search:true});
      }else{
        this.searchNode.value = "";
        this.setState({videos:videos, search:false});
      }
  }
  debounce(func, interval) {
    var timeout;
    return  ()=> {
      var context = this, args = arguments;
      var later = ()=> {
        timeout = null;
        func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, interval || 200);
    }
  }
  updateFilters(e){
    let filter =  e.target.name;
    console.log('filter: ', filter);
    let currentFilters = this.state.activeFilters;
    if(currentFilters.indexOf(filter)>-1){
      let index = currentFilters.indexOf(filter);
      currentFilters.splice(index, 1);
    }else{
      currentFilters.push(filter);
    }
    this.setState({activeFilters:currentFilters});
    this.filterVideos();
  }
  removeFilter(string){
    let currentFilters = this.state.activeFilters;
    let index = currentFilters.indexOf(string);
    currentFilters.splice(index, 1);
    this.setState({activeFilters:currentFilters});
  }
  filterCount(string){
    // let currentFilters = this.state.currentFilters();
    let type = string;
    let filtered = this.state.activeFilters.filter((item)=>{
      return item.indexOf(type) > -1
    })
    return filtered.length
  }
  handleSearch(e, val){
    // capture input string and hand it to the debounce method
    let string = e.target.value;
    this.debounce(this.searchVids(string), 500);
  }
  duration(ms){
    if(typeof ms !== 'undefined'){
      ms = parseInt(ms)*1000;
      let seconds = Math.floor((ms/1000)%60);
      if(seconds < 10){
        seconds = '0'+seconds;
      }
      let minutes = Math.floor((ms / (60 * 1000)) % 60);
      if(minutes < 10){
        minutes = '0'+minutes;
      }
      let hours = Math.floor((ms / (60 * 60 * 1000)) % 60)
      if(hours > 0){
        return hours+':'+minutes+':'+seconds;
      }else{
        return minutes+':'+seconds;
      }

    }

  }
  dateString(targetDate, opts){
    let date = new Date(targetDate);
    return date.toLocaleString('default', opts);
  }
  tagList(array, split){
    if(array && array.length > 0){
      let filtered = array.filter((item)=>{
        console.log(item, split);
        return item.indexOf(split) > -1;
      })
      let parsed = filtered.map((string)=>{
          let replaceString = split+':';
          // console.log('remove ', replaceString, ' from ', string);
          let mod = string.replace(replaceString, '');
          string = mod;
          return string;
      });
      return parsed.join(', ');
    }
  }
  readMore(){
    if(this.state.desc === 'description'){
      this.setState({activeVideoDescription:this.state.activeVid.long_description, desc:'long_description'});
    }else{
      this.setState({activeVideoDescription:this.state.activeVid.description, desc:'description'});
    }
  }

  loadMore(){
    let current = this.state.maxVids;
    let more = current + 12;
    this.setState({maxVids:more});
  }
  toggleNav(){
    let navActive = !this.state.navActive;
    this.setState({navActive:navActive});
  }
  clearActive(){
    let player = this.state.vjs_player;
    let params =  new URLSearchParams(window.location.search);
    if(Object.keys(params).length > 1){
      params.delete('video');
    }
    player.pause();
    this.setState({activeVid:{}});
    let updatedUrl = window.location.pathname;
    window.history.pushState({}, "Video Masters", updatedUrl);
  }
  render(){
      return(
        // hero wrapper
        <main id="videoWidget">
          <Helmet>
            <title>{this.state.activeVideoName ? "Video Masters : "+this.state.activeVideoName : "Brightcove Video Masters"}</title>
            <meta name="description" content={this.state.activeVideoDescription ? this.state.activeVideoDescription : "Brightcove Video Masters"}/>
            <meta name="image"  content={this.state.activeVid.poster ? this.state.activeVid.poster:"/header-bg.jpg"}/>
            <meta itemprop="name" content=""/>
            <meta itemprop="description"  content={this.state.activeVideoDescription ? this.state.activeVideoDescription : "Brightcove Video Masters"}/>
            <meta itemprop="image" content={this.state.activeVid.poster ? this.state.activeVid.poster:"/header-bg.jpg"}/>
            <meta prefix="og: http://ogp.me/ns#" name="og:type"             content="website"/>
            <meta prefix="og: http://ogp.me/ns#" name="og:title" content={this.state.activeVideoName ? "Video Masters : "+this.state.activeVideoName : "Brightcove Video Masters"}/>
            <meta prefix="og: http://ogp.me/ns#" name="og:description" content={this.state.activeVideoDescription ? this.state.activeVideoDescription : "Brightcove Video Masters"}/>
            <meta prefix="og: http://ogp.me/ns#" name="og:image" content={this.state.activeVid.poster}/>
            <meta prefix="og: http://ogp.me/ns#" name="og:url" content={window.location.href}/>
            <meta prefix="og: http://ogp.me/ns#" name="og:site_name" content="Video Masters by Brightcove"/>
          </Helmet>
          <nav className={this.state.navActive ? 'active':''}>
            <ul>
              <li>
                <a href="https://www.brightcove.com"><img src="https://img.brightcove.com/bc-logo-white.png" alt="Brightcove Logo"/></a>
              </li>
              <li>
                <Link to="/" onClick={()=>this.clearActive()}>All Episodes</Link>
              </li>
              {this.state.videos && this.state.videos.reverse().map((vid, index)=>(
                <li key={index}>
                  <Link to={'/'+vid.reference_id}>{(index+1)+'. '+vid.name}</Link>
                </li>
              ))}
              <li className="relatedHeader"><em>Related Reading</em></li>
              {Object.keys(allAssets()).map((cat, index)=>(
                <details key={index} className="relatedCategory"><summary>{cat}</summary>
                <ul>
                  { allAssets()[cat].map((item, index)=>(
                    <li key={index}><a href={item.link} target="_blank"  rel="noopener noreferrer">{item.title}</a></li>
                  ))}
                </ul>
                </details>
              ))}
            </ul>
          </nav>
          <div id="videos">
            {/*<a href="https://www.brightcove.com">
              <img src="/brightcove-logo.svg" className="bc-logo" alt="brightcove logo" />
            </a>*/}
            <header>
              <div className="menuToggle" onClick={()=>this.toggleNav()}>
                <img src="/menu.svg" alt="menu toggle"/>
                MENU
              </div>
              <Link to="/"><img src="/vm-logo.svg" className="vm-logo" alt="video masters logo" /></Link>
              <input type="text" id="videoSearch"  ref={ node => this.searchNode = node }  onChange={this.handleSearch} placeholder="Search Videos"></input>

            </header>
            <section className={Object.keys(this.state.activeVid).length == 0 ? 'active headerImage':'headerImage'}>
              <h1>Video Masters<br/>
                  Season 1: Video Marketing
              </h1>
              <p>A show for marketers who are ready to level up their video strategy skills. Join our host, Product Marketing Manager Lauren Barry, as she talks to the video marketing experts.</p>
              {this.state.videos[0] && <Link to={'/'+this.state.videos.reverse()[0].reference_id}><button className="cta">Watch The Latest Episode</button></Link>}
            </section>
            <section className={Object.keys(this.state.activeVid).length  > 0 ? 'active videoHero':'videoHero'}>
              <video-js id="heroPlayer" ref={ node => this.videoNode = node }  controls data-application-id data-player="D8QMZAODC" data-account="1160438696001" className="video-js"></video-js>
            </section>
            <section className={Object.keys(this.state.activeVid).length  > 0 ? 'active videoInfo':'videoInfo'}>
              <Sharing video={this.state.activeVid}/>
              <h2>{this.state.activeVideoName} { this.state.activeVid !== null && <small>{this.duration(this.state.activeVid.duration)}</small>}</h2>{/* this.state.activeVid !== null && this.state.activeVid.tags && <p>Tags: {this.tagList(this.state.activeVid.tags, 'topic')}</p>*/}
              <p>{this.state.activeVideoDescription}</p>
              <Link to="/" onClick={()=>this.clearActive()}>Back to All Videos</Link>
              <RelatedContent video={this.state.activeVid}/>
            </section>
            <VidList videos={this.state.videos}/>

          </div>
          <section id="form">
            {this.state.videos[0] && Object.keys(this.state.activeVid).length == 0 && <Form video={this.state.videos[0] } />}
            {Object.keys(this.state.activeVid).length > 0 && <Form video={this.state.activeVid} />}
          </section>

        </main>
      )
    }
}
export default VideoGrid;
// const domContainer = document.querySelector('#video-feed');
// ReactDOM.render(element(VideoGrid), domContainer);
