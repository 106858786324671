import '@babel/polyfill';
import 'url-search-params-polyfill';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import { BrowserRouter as Router,  Switch, Route, Link } from 'react-router-dom';
import './index.css';
import App from './App';
import VidFeed from './vid-feed';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(<Router> <Route path="/:id" component={VidFeed} /><Route path="/" exact component={VidFeed} /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
