import { SUBMIT_FORM, DOWNLOAD_ASSET } from "../actions/types";
const initialState = {
  formId:null,
  assetUrl:null
}
export default function(state = initialState, action){
  switch(action.type){
    case SUBMIT_FORM:{
      return action.payload
    }
    case DOWNLOAD_ASSET:{
      return action.payload
    }
    default:{
      return state;
    }
  }
}
