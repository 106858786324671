import { UPDATE_VIDEO } from "../actions/types";
const initialState = {
  video:{}
}
export default function(state = initialState, action){
  switch(action.type){
    case UPDATE_VIDEO:{
      const video = action.payload;
      return {
        ...state,
        video:video
      }
    }
    default:{
      return state
    }
  }
}
